import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_slider = _resolveComponent("el-slider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_slider, {
      modelValue: _ctx.models[`rating${_ctx.dataIndex}${_ctx.questionIndex}`],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.models[`rating${_ctx.dataIndex}${_ctx.questionIndex}`]) = $event)),
      onChange: _ctx.setNewValue,
      min: 0,
      max: 5,
      marks: _ctx.marks
    }, null, 8, ["modelValue", "onChange", "marks"])
  ]))
}