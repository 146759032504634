import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setNewValue($event?.target?.value))),
    type: "textarea",
    rows: "3",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.models[`text${_ctx.dataIndex}${_ctx.questionIndex}`]) = $event)),
    class: "form-control form-control-solid"
  }, "\n  ", 544)), [
    [_vModelText, _ctx.models[`text${_ctx.dataIndex}${_ctx.questionIndex}`]]
  ])
}