
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "radial-chart",
  props: {
    percent: {
      required: true,
      type: Number,
    },
    description: {
      required: true,
      type: String,
    },
    info: {
      required: true,
      type: String,
    },
  },
  setup: function (props) {
    watch(
      () => props.percent,
      () => {
         series.value = [props.percent];
      }
    );
    const series = ref([props.percent]);
    const chartOptions = ref({
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              formatter: function () {
                return props.info;
              },
              color: "rgba(0, 143, 251, 0.85)",
              fontSize: "24px",
              show: true,
            },
          },
        },
      },
      labels: [props.description],
      stroke: {
        lineCap: "round",
      },
    });
    return {
      series,
      chartOptions,
    };
  },
});
