

import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "TextareaComponent",
  props: ["items", "value", "dataIndex", "questionIndex"],
  emit: ["rating-value"],
  setup: function(props, { emit }) {
    const models = ref({});
    const marks = ref({
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5"
    });
    onMounted(() => {
      models.value[`text${props.dataIndex}${props.questionIndex}`] = props.value;
    });
    const setNewValue = (event) => {
      let data = {
        item: props.items.item,
        question: props.items.question,
        value: null,
        text:event,
      };
      emit("rating-value", data);
    };

    return {
      models,
      setNewValue,
      marks
    };
  }
});
