
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import RadialChart from "@/components/RadialChart.vue";
import { notify } from "@kyvg/vue3-notification";
import { savePDF } from "@progress/kendo-vue-pdf";
import "@progress/kendo-drawing";
import "@progress/kendo-licensing";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import RatingComponent from "@/components/RatingComponent.vue"
import TextareaComponent from "@/components/TextComponent.vue"
import InputComponent from "@/components/InputComponent.vue";

export default defineComponent({
  name: "survey",
  components: { InputComponent, RadialChart, RatingComponent, TextareaComponent },
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup: function (props) {
    const router = useRouter();
    const route = useRoute();
    let locked = ref(true);
    let submitted = ref(false);
    let submitButtonRef = ref<null | HTMLButtonElement>(null);
    let data = ref();
    let percentData = ref([]);
    let surveyID = ref();

    let surveyPercent = ref(0);
    let surveyValue = 0;
    let surveyMax = 0;
    onMounted(() => {
       getResults();
    });

    async function getResults() {
      surveyID.value = Number(route.params.id)
        ? route.params.id
        : router.push({ name: "users" });
      try {
        apiService
          .get(`/${props.id}/survey/${surveyID.value}/result`)
          .then(
            await function (resp) {
              if (resp.data.data) {
                data.value = resp.data.data;
                checkSubmit(resp.data?.data);
                percentData.value = resp.data.data;
              }
            }
          )
          .catch(function (error) {
            if (error.response && error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
          });
      } catch (e) {
      //  console.log(e);
      }
    }

    function checkSubmit(value) {
      value.some(function (item) {
        if (item.section_submitted === 1) {
          submitted.value = true;
          return true;
        }
      });
    }

    const submitting = (sectionID) => {
      let button = document.getElementById(`section-submit-${sectionID}`);
      button?.setAttribute("data-kt-indicator", "on");
      button?.setAttribute("disabled", "disabled");
      // Activate indicator
      let object = {
        section_id: sectionID,
        user_id: props.id,
        survey_id: surveyID.value,
        submitted: true,
      };
      apiService
        .put("/survey", object)
        .then(function ({ data }) {
          percentData.value = data.data;
          Swal.fire({
            text: "The section has been submitted",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          button?.removeAttribute("data-kt-indicator");
          button?.removeAttribute("disabled");
        })
        .catch(function (error) {
          if (error.response.status == 422) {
            button?.removeAttribute("data-kt-indicator");
            button?.removeAttribute("disabled");
          }
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    const getPercent = (answer, question) => {
      return answer && question ? Math.round((answer / question) * 100) : 0;
    };

    const getColor = (score) => {
      if (score < 40) {
        return "bg-danger";
      } else if (score >= 40 && score < 65) {
        return "bg-warning";
      } else {
        return "bg-success";
      }
    };
    const getScore = (value, max) => {
      surveyValue += value ? Number(value) : 0;
      surveyMax += max ? Number(max) : 0;
      surveyPercent.value = Math.round((surveyValue / surveyMax) * 100);
      return null;
    };

    const setValue = (
      sectionID,
      questionID,
      value,
      text
    ) => {
      let object = {
        value: value,
        text: text,
        section_id: sectionID,
        question_id: questionID,
        user_id: props.id,
        survey_id: surveyID.value,
        max_value: 5,
      };
      apiService
        .put("/survey", object)
        .then(function (resp) {
          if (resp.data.data) {
            // percentData.value = resp.data.data;
            notify({
              group: "foo-css",
              title: "Answer has been successfully updated",
              type: "success",
            });
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };

    const setRating = (resp) => {
      setValue(resp.item, resp.question, resp.value, resp.text)
    }

    let PdfExportRef = ref([]);

    const createPdf = (id) => {
      savePDF(PdfExportRef.value[id], {
        paperSize: "A4",
        margin: 40,
        fileName: `Report for `,
      });
    };
    return {
      data,
      setValue,
      submitting,
      submitButtonRef,
      getPercent,
      getColor,
      getScore,
      locked,
      submitted,
      percentData,
      createPdf,
      PdfExportRef,
       setRating
    };
  },
});
