
import { computed, defineComponent, onMounted } from "vue";
import Survey from "@/components/Survey.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "complete-survey",
  components: {
    Survey
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Assessment", []);
    });
    const store = useStore();

    const userID = computed(() => {
      return store.getters.currentUser.id;
    });
    return { userID };
  }
});
